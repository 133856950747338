import React from 'react';
import '../css/GettingStarted.css'; // Import your CSS file for styling
import image71 from "../../images/71SelexHelp.png";
import image72 from "../../images/72SelexHelp.png";
import image73 from "../../images/73SelexHelp.png";
import image74 from "../../images/74SelexHelp.png";




function Reduced() {
  return (
    <div className="project-details">
      <h2>Reduced Order Modeling</h2>
      <h4>1 . Discrete Analysis</h4>
      <p>
      The Discrete Analysis feature allows users to perform calculations for variables within specified ranges. Follow these steps to utilize this functionality      </p>
      <br/>
      <img src={image72} className='imagestyling'/>



      
      <img src={image71} className='imagestyling'/>
      <h4>Steps to Generate Discrete Matrix</h4>

      <ol>
        <li>
        Add Variables for Discrete Analysis:
          Click on the "Add Variable" button , Specify the variables you want to analyze by entering their minimum, maximum, and step values      </li>
        <li>
        Calculate: Click the Calculate button to generate the discrete matrix for each variable.          </li>
        <li>
        Below that, you can define name or symbol representing Independent variables.
        </li>
        <li>View Results:
        The results will be displayed in both a tabular format and a graphical representation for easy interpretation.</li>
        <li>Download Report: Optionally, download a detailed report of the discrete matrix analysis in a convenient format.</li>
        
      </ol>
    <p>This tool is perfect for exploring variable dependencies and trends with precision and clarity.</p>
<h4>2 . Stochastic Analysis</h4>
<p>The Stochastic Analysis feature enables users to generate stochastic matrices for selected variables within defined ranges. Here’s how to use it: </p>
<ol>
        <li>
        Add Variables: Define the variables by entering their minimum, maximum, and the desired number of runs. </li>
        <li>
        Calculate: Click the Calculate button to create stochastic matrices for each variable.</li>
        <li>
        View Results:
The results will be presented in both a tabular format and a graphical representation to aid in analysis.
        </li>

      <li>Download Report: You can download a comprehensive report detailing the stochastic matrices for further review or sharing.</li>        
      </ol>
<br />
<img src={image73} className='imagestyling'/> 

<br/>


<h4>3 . Dynamic Calculations </h4>
<p>The Dynamic Calculation step adds flexibility to your analysis by allowing you to define custom formulas using variables from previous steps. Follow these instructions:


<br />

</p>
<ol>
        <li>
        Add a New Variable: Introduce a new variable to be calculated dynamically. </li>
        <li>
        Define Formula: Use the variables declared in Step 1 (Discrete Analysis) or Step 2 (Stochastic Analysis) to create a custom formula.
        </li>       
         <li>
         Calculate: Click the Calculate button to compute the values for the new variable based on the formula.
        </li>
      <li>View Results:
      The results will be displayed in a tabular format and a graphical representation for detailed analysis.</li>        
      </ol>

<br/>
<img src={image74} className='imagestyling'/>    
    </div>
  );
}

export default Reduced;
