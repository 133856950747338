import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { baseURL } from "../Config/config";


const TCP = () => {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const socket = io('https://172.31.87.201:5000'); // Replace with your Flask server's address

        socket.on('connect', () => {
            console.log('Connected to WebSocket');
        });

        socket.on('message', (data) => {
            setMessages((prevMessages) => [...prevMessages, data.data]);
        });

        socket.on('disconnect', () => {
            console.log('Disconnected from WebSocket');
        });

        return () => socket.disconnect();
    }, []);

    return (
        <div>
            <h1>TCP Messages</h1>
            <ul>
                {messages.map((msg, index) => (
                    <li key={index}>{msg}</li>
                ))}
            </ul>
        </div>
    );
};

export default TCP;


/**
 * 
 * THis code is working socket2.py file
 * from flask import Flask
from flask_socketio import SocketIO, emit
import socket
import threading

app = Flask(__name__)
app.config['SECRET_KEY'] = 'secret!'
socketio = SocketIO(app, cors_allowed_origins="*")

HOST = '127.0.0.1'  # TCP server host
PORT = 5002         # TCP server port
allmessages = []

def tcp_server():
    with socket.socket(socket.AF_INET, socket.SOCK_STREAM) as s:
        s.bind((HOST, PORT))
        s.listen()
        print(f"TCP Server listening on {HOST}:{PORT}")
        while True:
            conn, addr = s.accept()
            with conn:
                print('Connected by', addr)
                while True:
                    data = conn.recv(1024)
                    if not data:
                        print("Connection closed by client")
                        break
                    string_data = data.decode('utf-8')
                    allmessages.append(string_data)
                    print(f"Received data: {string_data}")
                    
                    # Broadcast to WebSocket clients
                    socketio.emit('message', {'data': string_data})

# Run TCP server in a separate thread
threading.Thread(target=tcp_server, daemon=True).start()

@app.route('/')
def index():
    return "TCP to WebSocket bridge is running!"

if __name__ == '__main__':
    socketio.run(app, host='0.0.0.0', port=5000)

 * 
 * 
 * 
 */
